@import
"src/main/scss/mixins/media-query",
"src/main/scss/mixins/state-indicator",
"src/main/scss/functions/find-color-invert",
'tippy.js/dist/tippy';

// Default theme
.tippy-popper {
  // &[x-placement^='top'] {
  //   &.tippy {
  //     &-arrow {
  //       border-top-color: rgba(0,0,0,.6);
  //     }
  //   }
  // }
  //
  // &[x-placement^='bottom'] {
  //   .tippy {
  //     &-arrow {
  //       border-bottom-color: rgba(0,0,0,.6);
  //     }
  //   }
  // }
  //
  // &[x-placement^='left'] {
  //   .tippy {
  //     &-arrow {
  //       border-left-color: rgba(0,0,0,.6);
  //     }
  //   }
  // }
  //
  // &[x-placement^='right'] {
  //   .tippy {
  //     &-arrow {
  //       border-right-color: rgba(0,0,0,.6);
  //     }
  //   }
  // }

  .tippy {
    &-tooltip {
      background-color: rgba(0,0,0,.6);
    }

    &-content {
      color: #fff;
      font-family: var(--font-primary);
      font-weight: var(--type-weight-meta);
      font-size: var(--text-sm);
    }
  }
}

/*
  THEME::Dark solid theme
*/
.tippy-popper {
  // &[x-placement^='top'] {
  //   .dark-solid-theme {
  //     .tippy {
  //       &-arrow {
  //         border-top-color: rgba(0,0,0,1);
  //       }
  //     }
  //   }
  // }
  //
  // &[x-placement^='bottom'] {
  //   .dark-solid-theme {
  //     .tippy {
  //       &-arrow {
  //         border-bottom-color: rgba(0,0,0,1);
  //       }
  //     }
  //   }
  // }
  //
  // &[x-placement^='left'] {
  //   .dark-solid-theme {
  //     .tippy {
  //       &-arrow {
  //         border-left-color: rgba(0,0,0,1);
  //       }
  //     }
  //   }
  // }
  //
  // &[x-placement^='right'] {
  //   .dark-solid-theme {
  //     .tippy {
  //       &-arrow {
  //         border-right-color: rgba(0,0,0,1);
  //       }
  //     }
  //   }
  // }
  //
  // .dark-solid-theme {
  //   &.tippy {
  //     &-tooltip {
  //       background-color: rgba(0,0,0,1);
  //     }
  //   }
  // }
}
